// Function to dynamically load the Turnstile script and ensure it's ready
function loadTurnstileScript(callback) {
  if (!document.querySelector("script[src*='challenges.cloudflare.com']")) {
    const script = document.createElement("script")
    script.src = `${window.captcha_api_server_url}?render=explicit`
    script.async = true
    script.onload = callback
    document.body.appendChild(script)
  } else {
    callback() // Call the callback directly if the script is already loaded
  }
}

// Callback function when Turnstile is ready
function initializeTurnstile() {
  document
    .querySelectorAll("form:has(.cf-turnstile)")
    .forEach((formElement) => {
      formElement.addEventListener("submit", function (event) {
        event.preventDefault()

        // Check if the Turnstile challenge has already been solved
        if (formElement.querySelector("input[name='cf-turnstile-response']")) {
          formElement.submit()
          return
        }

        // Prevent multiple Turnstile widgets from being rendered in the same form
        if (!formElement.dataset.turnstileInitialized) {
          formElement.dataset.turnstileInitialized = "true" // Mark the form as initialized
          const turnstileDiv = formElement.querySelector(".cf-turnstile")
          if (turnstileDiv) {
            // Ensure the div has an ID for the Turnstile render function
            const turnstileId =
              turnstileDiv.id ||
              `turnstile-${Math.random().toString(36).substring(2, 9)}`
            turnstileDiv.id = turnstileId
            // Render the Turnstile widget explicitly using the ID
            turnstile.render(`#${turnstileId}`, {
              sitekey: window.captcha_site_key, // Use the site key from a global variable or directly
              callback: (token) => {
                // Insert the token into a hidden input within the form
                const tokenInput = document.createElement("input")
                tokenInput.type = "hidden"
                tokenInput.name = "cf-turnstile-response"
                tokenInput.value = token
                formElement.appendChild(tokenInput)

                formElement.submit()
              },
            })
          }
        } else {
          // If the widget is already initialized, submit the form directly
          formElement.submit()
        }
      })
    })
}

loadTurnstileScript(initializeTurnstile)
